//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCbrIuOnyMCEIB3W375jRLl1AoztZsDKD0",
  authDomain: "setupvideo-ac735.firebaseapp.com",
  projectId: "setupvideo-ac735",
  storageBucket: "setupvideo-ac735.firebasestorage.app",
  messagingSenderId: "1025595086889",
  appId: "1:1025595086889:web:6e0da0955b5fad385da438",
  measurementId: "",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
